import * as React from "react"

const content = [
    {
        question: "Como funciona o app da Dinerama?",
        answer: (
            <div className="answer">
                <p>O app da Dinerama é a melhor maneira de ganhar cashback nas suas compras do dia-a-dia. Veja como é
                    simples:</p>
                <ol>
                    <li>Baixe o app Dinerama</li>
                    <li>Ative ofertas antes de comprar</li>
                    <li>Compre em qualquer supermercado</li>
                    <li>Escaneie o QR Code da nota fiscal no app Dinerama, depois de comprar</li>
                    <li>A partir de R$40 acumulados, você pode resgatar o cashback e usá-lo como preferir.</li>
                </ol>
            </div>
        )
    },
    {
        question: "Posso comprar em qualquer supermercado?",
        answer: <p className="answer">Sim. Nascemos para colocar o poder de decisão na mão do consumidor. Ao invés de
            se inscrever em milhares de programas de fidelidade diferentes, com a Dinerama você concentra seu
            cashback em um único lugar.
            <li>
                <br />
                Para que sua participação seja válida, as notas fiscais enviadas devem obrigatoriamente ser de compras
                realizadas por você.
            </li>
            <li>
                <br />
                Importante: NFC-e, CF-e SAT e NF-e são os modelos válidos que aceitamos aqui na Dinerama.''
            </li>
        </p>
    },
    {
        question: "Como isso é possível?",
        answer: <p className="answer">O QR Code nas notas fiscais foi implementado em todo território nacional. É
            através dessa tecnologia que conseguimos reconhecer que uma compra realizada pelos nossos usuários contém
            produtos participantes das campanhas de cashback no nosso app.</p>
    },
    {
        question: "Por que preciso ativar as ofertas no app antes de comprar?",
        answer: <p className="answer">É dessa forma que garantimos que os produtos contidos na nota fiscal realmente
            eram participantes das campanhas de cashback. Se uma oferta não for ativada antes no app ou ainda, se for
            ativada somente após a compra, a nota fiscal será recusada e o cashback invalidado.</p>
    },
    {
        question: "Existe limite de quantidade de notas fiscais enviadas?",
        answer: <p className="answer">Sim. Para que mais pessoas possam usufruir das promoções, existem limites diários
            e semanais. Por semana, cada cliente pode enviar até 6 notas fiscais.<br /> <br /> Além disso, cada cliente pode enviar
            até 2 notas fiscais por dia do mesmo estabelecimento.<br /><br /> <span className="bold">Importante</span>: Consideramos a data da emissão da nota
            fiscal, portanto serão aceitas 6 notas de cada semana.<br /> <br /> A semana sempre se inicia no domingo e finaliza no
            sábado.<br /> <br /> Para que sua participação seja válida, as notas fiscais enviadas devem obrigatoriamente ser de
            compras realizadas por você.</p>
    },
    {
        question: "Como funcionam os ofertas com 100% de cashback?",
        answer: <p className="answer">Nosso objetivo é garantir que todos e todas recebam um benefício justo. Por isso,
            o valor do cashback é baseado no valor médio nacional do produto.<br /> <br /> Realizamos pesquisas e estudos com base nos valores informados pela indústria e estabelecemos esse
            valor médio como uma regra de segurança.<br /><br /> Isso significa que, mesmo que o preço do produto varie em diferentes regiões ou lojas, o cashback será calculado com base
            nesse valor médio nacional, garantindo a segurança e consistência nas ofertas.<br /> <br /></p>
    },
    {
        question: "Quanto tempo duram as ofertas?",
        answer: <p className="answer">As ofertas são atualizadas semanalmente, porém temos ofertas relâmpago que podem durar 1 ou mais dias. Fique atento (a)!!
            <br /> <br /> É importante ressaltar que as ofertas duram até o limite de cashback esgotar no app, então independente da data de finalização da oferta, ela poderá ser esgotada
            antes do prazo e isso será sinalizado no aplicativo.<br /><br /> Recomendamos que você sempre abra o card da oferta para verificar o status da mesma.<br /> <br /></p>
    },
    {
        question: "Preciso tirar foto da Nota Fiscal? Como é feito o envio para a Dinerama?",
        answer: (
            <div>
                <p>Não. O envio é feito ao escanear o QR Code ou digitar o código de acesso contendo 44 dígitos na nota
                    fiscal. É bem simples:</p>
                <ol>
                    <li>Abra o app Dinerama</li>
                    <li>Clique no ícone de escanear no menu inferior</li>
                    <li>Mire o quadrante que aparece no app sobre o QR Code da nota fiscal e aguarde a análise da sua
                        nota.
                    </li>
                </ol>
            </div>
        )
    },
    {
        question: "Quanto tempo leva para a análise da nota fiscal ser realizada?",
        answer: <p className="answer">O prazo é de até 30 dias após o envio da nota fiscal.</p>
    },
    {
        question: "Quais modelos de nota fiscal são válidos para envio?",
        answer: <p className="answer">NFC-e, CF-e SAT e NF-e são os modelos válidos que aceitamos.</p>
    },
    {
        question: "Ativei as ofertas no app, mas perdi ou esqueci de guardar a nota fiscal. Vou ganhar cashback?",
        answer: <p className="answer">Não. É necessário ativar as ofertas antes de comprar e também nos enviar a nota
            fiscal, comprovando que a compra foi realizada.</p>
    },
    {
        question: "Onde vejo o meu saldo e o status das minhas notas enviadas?",
        answer: <p className="answer">É bem fácil. O saldo fica disponível na tela principal, logo acima das ofertas do
            app. Você também pode consultar clicando no ícone do seu perfil e depois na aba extrato.</p>
    },
    {
        question: "Como faço o resgate do cashback?",
        answer: <p className="answer">A partir de R$40 acumulados em cashback, você pode clicar no botão "resgatar"
            dentro da sessão do seu extrato. Para isso, precisamos que a chave do seu pix
            esteja cadastrada no seu perfil.
            <li>< br/>Para que você adquira o direito a receber seu saldo de Cashback,
                é necessário ainda cumprir os requisitos: acumular saldo mínimo de R$ 40,00 e efetuar os
                procedimentos de solicitação de resgate.
            </li>
            <li> <br/> A Dinerama poderá solicitar informações complementares para liberação do resgate do Cashback, como a
                comprovação da identidade do usuário, por meio do envio de uma foto própria (selfie) ao lado de um
                documento de identidade; além dos documentos de comprovação de compra, através do envio de uma cópia de
                documento fiscal. As notas fiscais são de uso pessoal e intrasferível, portanto para que sua
                participação seja válida, as notas fiscais enviadas devem obrigatoriamente ser de
                compras realizadas por você.''
            </li></p>
    },
    {
        question: "Em quanto tempo o dinheiro cai na minha conta?",
        answer: <p className="answer">O prazo de análise é de até 30 dias corridos após a solicitação de resgate.
            A Dinerama poderá solicitar informações complementares para liberação do resgate do Cashback, como a
            comprovação da identidade do usuário, por meio do envio de uma foto própria (selfie) ao lado de um
            documento de identidade; além dos documentos de comprovação de compra, através do envio de uma cópia de
            documento fiscal. As notas fiscais são de uso pessoal e intransferível, portanto para que sua participação
            seja válida, as notas fiscais enviadas devem obrigatoriamente ser de compras realizadas por você.</p>
    },
    {
        question: "Como a Dinerama ganha dinheiro?",
        answer: <p className="answer">As marcas utilizam a Dinerama como um canal de anúncio direto com o consumidor.
            Recebemos uma comissão por isso e dividimos com você, em forma de cashback. Assim, todo mundo sai ganhando:
            parceiros, usuários e a Dinerama.</p>
    },
    {
        question: "Pago alguma coisa para usar a Dinerama?",
        answer: <p className="answer">Não. O app Dinerama é totalmente gratuito.</p>
    },
    {
        question: "É obrigatório conter CPF na nota fiscal?",
        answer: <p className="answer">Para que sua participação seja válida, as notas fiscais enviadas devem
            obrigatoriamente ser de compras realizadas por você. Indicamos que você solicite CPF na nota fiscal.
            Caso seja obrigatório o envio do CPF na nota fiscal, avisaremos no aplicativo e no e-mail.
            É importante que você tenha sempre uma cópia do cupom fiscal, caso seja necessário alguma reanálise
            de sua nota.</p>
    },
]

export default function Faq() {
    return (
        <div className="faq" id="faq">
            <h4 className="title">Algumas dúvidas frequentes</h4>
            {
                content.map((c, index) => (
                    <div className="wrap-collapsible" key={index}>
                        <input id={`question-${index}`} className="toggle" type="checkbox"></input>
                        <label htmlFor={`question-${index}`} className="label-toggle">
                            {c.question}
                            <span className="arrow-icon"/>
                        </label>
                        <div className="collapsible-content">
                            <div className="content-inner answer">
                                {c.answer}
                            </div>
                        </div>
                    </div>))
            }
        </div>
    )
}
